<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="md-layout md-gutter md-alignment-center">
          <div class="md-layout-item">
            <h4 class="title" style="font-weight:350;">
              Novo email :
            </h4>
          </div>
          <div>
            <md-button
              type="button"
              class="md-raised mt-4 md-success"
              @click="addNovoEmail()"
            >
              Adicionar
            </md-button>
          </div>
        </div>
        <div v-for="(email, index) in emails" :key="index">
          <div
            v-if="!isFetching"
            style="padding-top:15px;display:inline-block;"
          >
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Email - {{ index }}
              </h5></span
            >
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                :name="'email-' + index"
                :rules="{
                  email: true,
                  required: true,
                  max: 100
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email</label>
                  <md-input
                    v-model="email.email"
                    type="text"
                    :maxlength="100"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <ValidationProvider
                :name="'tipo email-' + index"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="tipoEmailSelect">Selecionar Tipo Email</label>
                  <md-select v-model="email.tipo" name="tipoTelefoneSelect">
                    <md-option
                      v-for="item in tipoEmailSelectOption"
                      :key="item.id"
                      :label="item.tipo"
                      :value="item.tipo"
                    >
                      {{ item.tipo }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="col-xs-2">
              <label>&nbsp;</label>
              <md-button
                type="button"
                class="md-just-icon md-danger"
                @click="removeEmail(index)"
              >
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
        </div>
        <div class="col-xs-2">
          <md-button type="submit" class="md-raised mt-4 md-success">
            CRIAR
          </md-button>
          &nbsp;
          <!--<md-button
            @click="validarProximo()"
            class="md-raised md-success mt-4"
          >
            PROXIMO >
          </md-button>-->
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { checkAllResponses } from "@/modules/servidor_publico/js/checkAllResponses";
import { checkAllResponsesPensionista } from "@/modules/pensionista/js/checkAllResponsesPensionista";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export const TIPO_EMAIL = [
  { id: 1, tipo: "PESSOAL" },
  { id: 2, tipo: "PROFISSIONAL" }
];

export default {
  props: {
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    ...mapGetters("email", {
      emailState: "emailState"
    }),
    user_profile_state: state => state.user_profile
  },
  beforeMount() {
    this.getPapel();
  },
  data() {
    return {
      isFetching: true,
      papel: "",
      tipoEmailSelect: "",
      tipoEmailSelectOption: TIPO_EMAIL,
      email: {
        email: "",
        tipo: "",
        tipoProprietario: this.tipoProprietario
      },
      emails: []
    };
  },
  mounted() {
    if (this.tipoProprietario === "SERVIDOR") {
      this.loadServidor();
    }
    if (this.tipoProprietario === "PENSIONISTA") {
      this.loadPensionista();
    }
  },
  methods: {
    ...mapActions("email", ["ActionSetEMAIL"]),
    ...mapActions("servidor_publico", [
      "ActionSubmitServidorAllData",
      "ActionSetSERVIDOR_PUBLICO"
    ]),
    ...mapActions("pensionista", [
      "ActionSubmitPensionistaAllData",
      "ActionSetPENSIONISTA"
    ]),

    addNovoEmail: function() {
      this.ActionSetEMAIL(this.emails);
      this.emails.push(Vue.util.extend({}, this.email));
    },
    removeEmail: function(index) {
      Vue.delete(this.emails, index);
      this.ActionSetEMAIL(this.emails);
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    /*validarProximo() {
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.ActionSetEMAIL(this.enderecos);
            this.emitToParent("arquivos");
            window._Vue.$router
              .push({ name: "Novos Arquivos" })
              .catch(err => {});
          }
        }
      });
    },*/
    async loadForm() {
      if (this.emailState.length > 0) {
        Object.keys(this.emailState).map(key => {
          return this.emails.push(Vue.util.extend({}, this.emailState[key]));
        });
      } else {
        this.emails = JSON.parse(
          JSON.stringify([
            {
              email: "",
              tipo: "",
              tipoProprietario: this.tipoProprietario
            }
          ])
        );
      }
      this.isFetching = false;
    },
    async loadServidor() {
      if (
        this.servidorPublicoSelected.cpfServidor &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null
      ) {
        console.log(this.servidorPublicoSelected);
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Novo Cadastro" }).catch(err => {});
      }
    },
    async loadPensionista() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.cpfPensionista
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor preencha o Cadastro antes de avançar as Etapadas !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router
          .push({ name: "Novo Cadastro Pensionista" })
          .catch(err => {});
      }
    },
    async submit() {
      try {
        this.ActionSetEMAIL(this.emails);
        let papel = this.papel;
        //let form = this.dependentes;
        this.emails.map(e => {
          Object.keys(e).map(key => {
            if (key === "idEntidade") {
              if (this.tipoProprietario === "SERVIDOR") {
                e[key] = this.servidorPublicoSelected.idEntidade;
              }
              if (this.tipoProprietario === "PENSIONISTA") {
                e[key] = this.pensionistaSelected.idEntidade;
              }
            }
            return e[key];
          });
        });
        if (this.tipoProprietario === "SERVIDOR") {
          this.ActionSubmitServidorAllData({
            papel
          }).then(response => {
            checkAllResponses(response);
          });
        }
        if (this.tipoProprietario === "PENSIONISTA") {
          this.ActionSubmitPensionistaAllData({
            papel
          }).then(response => {
            checkAllResponsesPensionista(response);
          });
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>
<style>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: lowercase;
}

#container {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  background-color: lightyellow;
}
#container > div {
  width: 100px;
  height: 100px;
  border: 2px dashed red;
}
</style>
